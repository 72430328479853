import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { api_url, credentials } from './api_variables.js'

export const planApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: `${api_url}/plan`,
        credentials
    }),
    endpoints: builder => ({
        getPlanById: builder.query({
            query: planId => `/${planId}`
        }),
        getListOfPlans: builder.query({
            query: () => ``
        }),
        getPtileForAdjustments: builder.query({
            query: q => {
                const adjustmentFields = Object
                    .keys(q)
                    .filter(key => key.match(/^adjustment*/i))

                const adjustmentsQuery = adjustmentFields
                    .reduce((query, field, index) => `${query}${field}=${q[field]}${index === adjustmentFields.length - 1 ? '' : '&'}`, '?')

                return `/ptile/${q.planId}${adjustmentsQuery}`
            }
        })
    }),
    reducerPath: 'plan'
})

export const { useGetPlanByIdQuery, useGetListOfPlansQuery, useGetPtileForAdjustmentsQuery } = planApi