import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { api_url, credentials } from './api_variables.js'

export const performanceApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: `${api_url}/performance`,
        credentials
    }),
    endpoints: builder => ({
        getPerformanceByPlanId: builder.query({
            query: ({ planId, period }) => `/plan/${planId}/${period}`
        }),
        getSPIndexPerformance: builder.query({
            query: planId => `/sp-index?planId=${planId}`
        }),
        getIndustryPerformance: builder.query({
            query: ({ period, detailed = false, planId = null }) => `/industry/${period}?detailed=${detailed}&planId=${planId}`
        }),
        getPerformanceRanges: builder.query({
            query: (planId = null) => `/ranges?planId=${planId}`
        }),
        getDisclaimers: builder.query({
            query: ({ planId, emails }) => `/disclaimers?planId=${planId}&email=${emails.join('&email=')}`
        })
    }),
    reducerPath: 'performance'
})

export const { useGetPerformanceByPlanIdQuery, useGetSPIndexPerformanceQuery, useGetIndustryPerformanceQuery, useGetPerformanceRangesQuery, useGetDisclaimersQuery } = performanceApi