import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { api_url, credentials } from './api_variables.js'

export const timeApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: `${api_url}/time`,
        credentials
    }),
    endpoints: builder => ({
        getPriceDates: builder.query({
            query: () => '/price-dates'
        }),
        getGlidepathsByDate: builder.query({
            query: date => `/glidepaths/${date}`
        }),
        getSPIndexGlidepath: builder.query({
            query: () => 'sp-index/gp'
        })
    }),
    reducerPath: 'time'
})

export const { useGetPriceDatesQuery, useGetGlidepathsByDateQuery, useGetSPIndexGlidepathQuery } = timeApi