import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './adsmith/css/main.css'
import './adsmith/css/overrides.css'
import './styles/stadion-adsmith-overrides.css'
import './styles/stadion-styles.css'

import { Provider } from 'react-redux'
import { store } from './store/store'

import HomePage from './pages/Home'
import TimePage from './pages/Time'
import LineupPage from './pages/Lineup'
import LineupDetailsPage, { StartingLineup, SelectedLineup, Model, Glidepaths, Performance } from './pages/LineupDetails'
import Layout from './Layout/Layout'
import SelectAttendee from './pages/SelectAttendee'

function App() {
  return (
    <Provider store={ store }>
      <Router>
          <Layout>
            <Routes>
              <Route path="/" element={ <HomePage /> }></Route>
              <Route path="/time" element={ <TimePage /> }></Route>
              <Route path="/select-attendee" element={ <SelectAttendee /> }></Route>
              <Route exact path="/select-lineup" element={ <LineupPage /> } ></Route>
              <Route path="/lineup/:planId" element={ <LineupDetailsPage /> } >
                <Route path="/lineup/:planId/all-funds" element={ <StartingLineup /> }></Route>
                <Route path="/lineup/:planId/selected-funds" element={ <SelectedLineup /> }></Route>
                <Route path="/lineup/:planId/model" element={ <Model /> }></Route>
                <Route path="/lineup/:planId/paths" element={ <Glidepaths /> }></Route>
                <Route path="/lineup/:planId/performance" element={ <Performance /> }></Route>
              </Route>
            </Routes>
          </Layout>
      </Router>
    </Provider>
  );
}

export default App;
