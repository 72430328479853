import React, { useState, useEffect } from 'react'

import HighchartsMore from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import { useGetPriceDatesQuery, useGetGlidepathsByDateQuery } from '../services/401k_toolbox/time'

HighchartsMore(Highcharts)

export default function Page() {
    const [ priceDate, setPriceDate ] = useState('')
    const [ resourceFilter, setResourceFilter ] = useState({
        'StoryLine': true,
        'S&P Index': true
    })
    const [ paths, setPaths ] = useState([])
    const [ presentationMode, setPresentationMode ] = useState(true)

    const priceDatesResponse = useGetPriceDatesQuery()
    const glidepathsResponse = useGetGlidepathsByDateQuery(priceDate, { skip: !Boolean(priceDate) })

    return (
        <main class="white">
            <div className="content-wrapper-lg">
                <div className="text-center">
                    <h4 className="navy">
                        Glide path comparison of Stadion's managed account technology<br />
                        with industry funds and S&amp;P Target Date Index
                    </h4>
                </div>
                <div className="p2-tb">
                    <div className="flex-row" style={ { flexWrap: 'nowrap' } }>
                        <div className="flex-75 text-left">
                            <p>
                                <GlidepathsChart
                                    priceDate={ priceDate }
                                    glidepathsResponse={ glidepathsResponse }
                                    resourceFilter={ resourceFilter }
                                    paths={ paths }
                                    presentationMode={ presentationMode }
                                />
                            </p>
                        </div>
                        <div className="flex-25">
                            <div className="pl-1">
                                <PeriodSelector
                                    priceDatesResponse={ priceDatesResponse }
                                    priceDate={ priceDate }
                                    setPriceDate={ setPriceDate }
                                />
                                <AddPathForm
                                    glidepathsResponse={ glidepathsResponse }
                                    paths={ paths }
                                    setPaths={ setPaths }
                                    resourceFilter={ resourceFilter }
                                    setResourceFilter={ setResourceFilter }
                                />
                                <Legend
                                    glidepathsResponse={ glidepathsResponse }
                                    resourceFilter={ resourceFilter }
                                    setResourceFilter={ setResourceFilter }
                                    paths={ paths }
                                    setPaths={ setPaths }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <p className="small">
                    1 The managed account glide path lines represent the midpoint between the maximum and minimum equity exposure for each of the paths presented.
                    As market conditions fluctuate actual equity exposure of managed account glide paths will be more or less than the midpoint.
                </p>
                <p className="small">
                    2 The S&amp;P Target Date Index glide path was created by Stadion by combining the equity allocations of S&amp;P Target Date Index vintages.
                    To create a smooth glide path, Stadion assumes the difference in equity allocation percentages between target date vintages will adjust
                    equally on an average basis each year. For example if there is a 10% equity allocation difference between a 2030 vintage and 2040 vintage,
                    the equity allocation will adjust 1% each year.
                </p>
                <p className="small">
                    3 The grey “TDF Universe” graphic reflects the total range of possible equity exposures across the entire universe of Target Date Funds.
                    Results based on 151 Target Date Fund Series, which includes registered mutual funds, and non-registered collective investment funds
                    and insurance accounts. Collective investment funds and insurance accounts are only available for investment to qualified retirement plan
                    assets such as 401(k) plans. Data source for industry information: Stadion Retirement Data, June 30, 2022.
                </p>
            </div>
            {/* <div className="legend-card" onClick={ () => setPresentationMode(!presentationMode) } title="Hides tooltips and hover effect on chart elements">
                <span className={`legend-card-title ${presentationMode ? 'secondary-dark' : 'secondary'}`}>
                    Presentation Mode
                </span>
                <span className="legend-card-icon">
                    <i className={
                        `material-icons md-toggle_${presentationMode ? 'on' : 'off'} ${presentationMode ? 'primary' : 'secondary'}`
                        }>
                    </i>
                </span>
            </div>
            <h3 className="h3-stadion">
                Series
            </h3> */}
        </main>
    )
}

function PeriodSelector(props) {
    const { priceDatesResponse, priceDate, setPriceDate } = props

    const { isLoading, isError, data } = priceDatesResponse

    useEffect(() => {
        if (data && !priceDate)
            setPriceDate(data[0].date)
    })

    return null

    if (isLoading)
        return <span>Loading...</span>

    if (isError)
        return <span>Error loading periods</span>

    if (!priceDate)
        return null

    const [ firstPriceDate ] = data

    return (
        <select className="stadion-select" value={ priceDate } onChange={ e => setPriceDate(e.target.value) }>
            {
                [ firstPriceDate ]
                    .map(pd => <option key={ pd.date } value={ pd.date }>{ pd.label }</option>)
            }
        </select>
    )
}

function GlidepathsChart(props) {
    const { priceDate, glidepathsResponse, resourceFilter, paths, presentationMode } = props

    if (glidepathsResponse.isLoading)
        return <span>Loading...</span>

    if (glidepathsResponse.isError)
        return <span>Error fetching glidepaths data</span>


    if (!priceDate)
        return null

    const options = generateChartOptions(glidepathsResponse.data.resourceSeries, resourceFilter, paths, presentationMode)

    if (!options.series.length)
        return (
            <div>
                <span>Select Glidepaths to Display</span>
            </div>
        )

    return (
        <div>
            <HighchartsReact
                highcharts={ Highcharts }
                options={ options }
                immutable={ true }
            />
        </div>
    )
}

function Legend(props) {
    const { glidepathsResponse, paths, setPaths } = props

    if (glidepathsResponse.isLoading)
        return null

    if (glidepathsResponse.isError)
        return <span>Error fetching resources</span>

    if (!glidepathsResponse.data)
        return null

    return (
        <ul className="key">
            {
                [
                    ...paths
                        .map(path => {
                            const visible = path.display
            
                            function onClick() {
                                const updatedPaths = paths.map(p => {
                                    if (p.path_name !== path.path_name)
                                        return p
                                    else
                                        return {
                                            ...path,
                                            display: !path.display
                                        }
                                })
            
                                setPaths(updatedPaths)
                            }
            
                            return (
                                <li key={ path.path_name } className="clickable" onClick={ onClick }>
                                    <span className="dot-small" style={ { backgroundColor: visible ? path.color : '' } }></span>
                                    { path.path_name }
                                </li>
                            )
                        })
                ]
            }
        </ul>
    )
}

function AddPathForm(props) {
    const [ pathName, setPathName ] = useState('')

    const { glidepathsResponse, setPaths, resourceFilter, setResourceFilter } = props

    if (!glidepathsResponse.data)
        return null

    const { paths } = glidepathsResponse.data

    function handleSubmit(e) {
        e.preventDefault()

        const path = paths.find(p => p.path_name === pathName)

        setPathName('')
        setPaths([ ...props.paths, { ...path, display: true } ])
    }

    const buttonDisabled = !Boolean(paths.find(s => s.path_name === pathName))

    return (
        <div className="graph-control">
            <form onSubmit={ handleSubmit } id="add-time-series-form">
                <label htmlFor="add-series">Add series to chart</label>
                <div className="flex-row">
                    <div className="flex-65">
                        <input
                            type="text"
                            id="add-series"
                            name="add-series"
                            list="paths"
                            value={ pathName }
                            onChange={ e => setPathName(e.target.value) }
                            className="stadion-input"
                        />
                    </div>
                    <div className="flex-35">
                        <input type="submit" disabled={ buttonDisabled } value="Add"></input>
                    </div>
                </div>
                <datalist id="paths">
                    {
                        paths
                            .filter(p => !Boolean(props.paths.find(path => path.path_name === p.path_name)))
                            .map(p => <option key={ p.path_name }>{ p.path_name }</option>)
                    }
                </datalist>
                {
                    glidepathsResponse.data.resourceSeries
                        .map(resource => {
                            const visible = Boolean(resourceFilter[resource.resourceName])
                            const onClick = () => setResourceFilter({
                                ...resourceFilter,
                                [resource.resourceName]: !Boolean(resourceFilter[resource.resourceName])
                            })
            
                            return (
                                <div key={ resource.resourceName } className="p1-tb flex-row align-center">
                                    <div className="flex-65">
                                        <strong>
                                            { resource.resourceName }
                                            { resource.resourceName === 'StoryLine' ? <sup>1</sup> : null }
                                            { resource.resourceName === 'S&P Index' ? <sup>2</sup> : null }
                                            { resource.resourceName === 'Industry' ? <sup>3</sup> : null }
                                        </strong>
                                    </div>
                                    <div className="flex-35 text-right">
                                        <label className="switch">
                                            <input type="checkbox" checked={ visible } onChange={ onClick } />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                            )
                        })
                }
            </form>
        </div>
    )
}

function generateChartOptions(data, resourceFilter, paths, presentationMode) {
    if (!data)
        return {}

    const series = [
        ...data
            .filter(resource => resourceFilter[resource.resourceName])
            .reduce((series, resource) => {
                return [
                    ...series,
                    ...resource.data.map(mapResponseToChartSeries)
                ]
            }, []),
        ...paths
            .filter(path => path.display)
            .map(path => {
                return {
                    name: path.path_name,
                    color: path.color,
                    data: path.glidepath.map(dp => {
                        return {
                            x: dp.ret_year,
                            y: dp.eq
                        }
                    })
                }
            })
    ]

    const marker = {
        enabled: false,
        symbol: 'circle'
    }

    return {
        chart: {
            height: Math.round(window.innerHeight - 275)
        },
        series,
        title: {
            text: ''
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            arearange: {
                marker,
                states: {
                    hover: {
                        enabled: false
                    },
                    inactive: {
                        enabled: false
                    }
                }
            },
            line: {
                marker,
                lineWidth: 10,
                states: {
                    hover: {
                        enabled: !presentationMode
                    },
                    inactive: {
                        enabled: !presentationMode
                    }
                }
            }
        },
        tooltip: {
            enabled: !presentationMode
        },
        yAxis: {
            max: 100,
            min: 0,
            tickInterval: 10,
            tickWidth: 1,
            lineWidth: 1,
            title: {
                text: 'Equity Allocation (%)'
            }
        },
        xAxis: {
            tickInterval: 10,
            reversed: true,
            title: {
                text: 'Years to/from retirement'
            },
            tickWidth: 0
        }
    }
}

function mapResponseToChartSeries(series, index) {
    if (series.pathInfo.path_name === 'Industry')
        return {
            name: series.pathInfo.path_name,
            color: '#bdbdbd',
            data: [ ...series.glidepath ],
            zIndex: -10,
            type: 'arearange',
            lineWidth: 0
        }
    else
        return {
            name: series.pathInfo.path_name,
            color: series.seriesInfo.series_color,
            data: series.glidepath
                .map(point => ({
                    y: point.eq,
                    x: point.ret_year
                })),
            lineWidth: (series.pathInfo.path_name === 'S&P Index' || !series.pathInfo.path_name.includes('Percentile')) ? 6 : 2,
            zIndex: -index,
            type: 'line'
        }
}

export const TimeHeader = () => (
    <div id="plan-lineup-nav" role="navigation" aria-label="plan lineup nav">
      <ul>
        <li>
          <a href="" className="active" onClick={ e => e.preventDefault() }>Industry Analysis</a>
        </li>
      </ul>
    </div>
)