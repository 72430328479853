import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { planApi } from '../services/401k_toolbox/plan'
import { timeApi } from '../services/401k_toolbox/time'
import { performanceApi } from '../services/401k_toolbox/performance'
import { authApi } from '../services/401k_toolbox/auth'
import { disclaimersApi } from '../services/401k_toolbox/disclaimers'

import { reducer as lineupPage } from './lineup'
import attendees from './attendees'

export const store = configureStore({
    reducer: {
        [planApi.reducerPath]: planApi.reducer,
        [timeApi.reducerPath]: timeApi.reducer,
        [performanceApi.reducerPath]: performanceApi.reducer,
        [authApi.reducerPath]: authApi.reducer,
        [disclaimersApi.reducerPath]: disclaimersApi.reducer,
        lineupPage,
        attendees
    },
    // middleware: getDefaultMiddleware => getDefaultMiddleware().concat(planApi.middleware),
    devTools: true
})

setupListeners(store.dispatch)