import React, { Fragment } from 'react'
import Header from './Header'
import SelectAttendee from '../pages/SelectAttendee'
import { useNavigate, useLocation } from 'react-router'
import '../styles/stadion-adsmith-overrides.css'
import { useAuthenticateQuery } from '../services/401k_toolbox/auth'
import { api_url } from '../services/401k_toolbox/api_variables'
import { useSelector } from 'react-redux'

function Layout(props) {
    const attendees = useSelector(state => state.attendees.attendees)
    const [ attendee ] = attendees
    const navigate = useNavigate()
    const location = useLocation()
    const authResponse = useAuthenticateQuery()

    if (authResponse.isLoading)
        return <p>Authenticating...</p>

    if (authResponse.isError) {
        window.location.replace(`${api_url}/auth/authenticate?relayState=${window.location.href}`)

        return <p>Redirecting to Duo</p>
    }

    if (attendee.name)
        return (
            <Fragment>
                <Header hasAttendee={ true } />
                { props.children }
            </Fragment>
        )
    else {
        if (location.pathname !== '/select-attendee')
            navigate('/select-attendee')

        return (
            <Fragment>
                <Header />
                <SelectAttendee />
            </Fragment>
        )
    }        
}

export default Layout