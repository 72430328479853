import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'
import { Route, Routes } from 'react-router-dom'

import logoStadion from '../adsmith/img/global/logo-stadion.svg'
import iconAccount from '../adsmith/img/global/icon-account.svg'

import { LineupDetailsTabBar } from '../pages/LineupDetails'
import { TimeHeader } from '../pages/Time'

export default function Header(props) {
    const { hasAttendee = false } = props
    const navigate = useNavigate()

    return (
      <header className="interior">
        <div className="content-wrapper-lg">
          <div id="main-nav" role="navigation" aria-label="main">
            <a className="logo-link" href="" onClick={ e => { e.preventDefault(); navigate('/') } }>
              <img src={ logoStadion } alt="Stadion Logo Main" className="logo" width={ 109 } />
            </a>
            {
              hasAttendee ?
              <Fragment>
                <ul>
                  {/* <li><a href="#">Links</a></li> */}
                  <li><a href="" onClick={ e => { e.preventDefault(); navigate('/') } } className="home-btn">Home</a></li>
                  <li>
                    <a onClick={ e => { e.preventDefault(); navigate('/select-attendee') } } className="account">
                      <img src={ iconAccount } alt="Account Logo" />
                    </a>
                  </li>
                </ul>
              </Fragment> : null
            }
          </div>
          {
            hasAttendee ?
            <Routes>
              <Route path="/lineup/:planId/*" element={ <LineupDetailsTabBar /> } >
              </Route>
              <Route path="/time" element={ <TimeHeader /> }></Route>
            </Routes> : null
          }
        </div>
      </header>
    )
}
