import { createSlice } from '@reduxjs/toolkit'
import { v4 as uuidv4, v4 } from 'uuid'

const attendee = {
    name: '',
    email: '',
    firmName: ''
}

const attendees = [ { ...attendee, id: v4() } ]

let initialState

try {
    const attendees = JSON.parse(sessionStorage.attendees)

    if (!Array.isArray(attendees))
        throw new Error('Property attendees must be an array of attendees')

    initialState = { attendees }
}
catch (e) {
    console.log(e)

    initialState = { attendees } 
}

// const initialState = sessionStorage.attendee ? JSON.parse(sessionStorage.attendee) : attendees

export const attendeeSlice = createSlice({
    name: 'attendees',
    initialState,
    reducers: {
        setAttendees: (state, action) => {
            state.attendees = action.payload.attendees
            state.groupId = v4()
        },
        addAttendee: state => {
            state.attendees = [
                ...state.attendees,
                {
                    ...attendee,
                    id: v4()
                }
            ]
        },
        removeAttendee: (state, action) => {
            state.attendees = state.attendees.filter(a => a.id !== action.payload.id)
        },
        cleanupAttendees: state => {
            state.attendees = state.attendees.filter(a => a.name)
        }
    }
})

export const { setAttendees, addAttendee, removeAttendee, cleanupAttendees } = attendeeSlice.actions

export default attendeeSlice.reducer 