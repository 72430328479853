import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { api_url, credentials } from './api_variables.js'

export const authApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: `${api_url}/auth`,
        credentials
    }),
    endpoints: builder => ({
        authenticate: builder.query({
            query: () => '/authenticate'
        })
    }),
    reducerPath: 'auth'
})

export const { useAuthenticateQuery } = authApi