import React, { Fragment, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetListOfPlansQuery } from '../services/401k_toolbox/plan'
import { ReactComponent as MgdAccountImage } from '../adsmith/img/04-plan-lineup-p1/storyline-mgd-account_060524.svg'

export default function Page() {
    const [ planQuery, setQuery ] = useState('')
    const plansResponse = useGetListOfPlansQuery()
    const navigate = useNavigate()

    if (plansResponse.isLoading)
        return <span>Loading...</span>

    if (plansResponse.isError)
        return <span>Error fetching the list of plans</span>

    const { data } = plansResponse
    
    const plan = data
        .find(p => `${p.vendor} - ${p.company_name}` === planQuery)

    if (plan) {
        setTimeout(() => navigate(`/lineup/${plan.plan_id}/all-funds`), 50)
    }

    return (
        <Fragment>
            <main>
                <section className="investment-lineup-search">
                    <div className="content-wrapper-xs">
                        <div className="p1-tb">
                            <h3 className="navy">
                                Bringing your plan's investment lineup to life
                            </h3>
                            <h4 className="teal mt-2">
                                Search Lineup
                            </h4>
                        </div>
                        <div className="flex-row align-center row-space-between record-keeper">
                            <label htmlFor="plan-search">
                                Recordkeeper:
                            </label>
                            <input
                                className="stadion-input"
                                id="plan-search"
                                value={ planQuery }
                                onChange={ e => setQuery(e.target.value) }
                                list="plans"
                                type="search"
                                autoComplete="off"
                            />
                            {
                                planQuery.length > 2 ?
                                <datalist id="plans">
                                    { data
                                        .map(plan => <option key={ plan.plan_id } value={ `${plan.vendor} - ${plan.company_name}` } />)
                                    }
                                </datalist> : null
                            }
                        </div>
                    </div>
                </section>
                <div className="content-wrapper-lg mgd-image-container">
                    <div className="text-center">
                        <h3 className="navy">Managed Account</h3>
                        <p>Personalized participant allocations</p>
                    </div>
                    <MgdAccountImage alt="Storyline Managed Account Graphic" id="mgd-account-image" />
                    <p className="small text-center">1The list represents examples of participant data inputs. Stadion may consider using any input(s) referenced,
                    as well as inputs that are not listed. Actual data used may vary based on the recordkeeper and participant.</p>
                </div>
            </main>
            <footer id="footer" aria-label="footer">
                <div className="content-wrapper-lg">
                    <span className="teal-footer">Disclaimers</span>
                    <p>
                        Stadion Money Management, LLC ("Stadion") is a registered investment adviser under the Investment Advisers Act of 1940. Registration does not imply a certain level of skill or
                        training. More information about Stadion, including fees, can be found in Stadion's ADV Part 2, which is available free of charge.
                    </p>
                    <p>
                        1 The list represents examples of participant data inputs. Stadion may consider using any input(s) referenced, as well as inputs that are not listed. Actual data used may vary based on the recordkeeper and participant.
                    </p>
                    <p>
                        2 Life expectancy is derived using state and gender specific data derived from current CDC Life tables plus one standard error (standard deviation) to account for longevity risk.
                    </p>
                    <p>
                        3 Desired cash withdrawal is a participant input based on a post-retirement, periodic withdrawal from their retirement plan account. The desired withdrawal amount may not be attainable based on factors such as time horizon, account balance, investment performance, and availability of suitable investment allocations. The frequency of the desired withdrawal may not be available based on the design of a plan’s distribution features.
                    </p>
                    <p>
                        SMM-2407-12
                    </p>
                </div>
            </footer>
        </Fragment>
    )
}