import React, { Fragment, useEffect, useState } from 'react'
import {
    useNavigate,
    useParams,
    useLocation,
    Outlet
} from 'react-router-dom'
import { useSelector, useDispatch, useStore } from 'react-redux'
import { groupBy, sortBy, last, first } from 'underscore'
import moment from 'moment'
import { NavLink } from 'react-router-dom'

import { useGetPlanByIdQuery, useGetPtileForAdjustmentsQuery } from '../services/401k_toolbox/plan'
import { useGetSPIndexGlidepathQuery } from '../services/401k_toolbox/time'
import { useGetPerformanceRangesQuery, useGetPerformanceByPlanIdQuery, useGetSPIndexPerformanceQuery, useGetIndustryPerformanceQuery, useGetDisclaimersQuery } from '../services/401k_toolbox/performance'
import { useGeneralDisclaimersQuery, useLineupDisclaimersQuery, usePerformanceDisclaimersQuery } from '../services/401k_toolbox/disclaimers'
import { actions as lineupPageActions } from '../store/lineup'

import HighchartsMore from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

import xlsx from 'json-as-xlsx'

import { ReactComponent as ChartEquityImg} from '../adsmith/img/06-selection-process/chart-equity.svg'
import { ReactComponent as FixedIncomeImg } from '../adsmith/img/06-selection-process/chart-fixedincome.svg'

HighchartsMore(Highcharts)

const steps = planId => [
    { label: 'Choose Lineup', route: `/select-lineup` },
    { label: 'Plan Lineup', route: `/lineup/${planId}/all-funds` },
    { label: 'Selected Lineup', route: `/lineup/${planId}/selected-funds` },
    { label: 'Participant Allocations', route: `/lineup/${planId}/paths` },
    { label: 'Sample Allocations', route: `/lineup/${planId}/model` },
    { label: 'Performance', route: `/lineup/${planId}/performance` }
]

const retirementAge = 67

export default function LineupDetails(props) {
    return (
        <main className="white">
            <Outlet />
        </main>
    )
}

export function LineupDetailsTabBar() {
    const params = useParams()

    return (
        <div id="plan-lineup-nav" role="navigation" aria-label="Plan lineup nav">
            <ul>
                {
                    steps(params.planId).map(step => (
                        <li
                            key={ step.label }
                        >
                            <NavLink to={ step.route } exact>{ step.label }</NavLink>
                        </li>
                    ))
                }
            </ul>
        </div>
    )
}

export function StartingLineup(props) {
    const params = useParams()
    const planResponse = useGetPlanByIdQuery(params.planId)
    const lineupDisclaimersResponse = useLineupDisclaimersQuery(params.planId)

    if (planResponse.isLoading || lineupDisclaimersResponse.isLoading)
        return <h3 className="teal">Loading...</h3>

    if (planResponse.isError || lineupDisclaimersResponse.isError)
        return <h3 className="navy">Error fetching lineup</h3>

    const { lineup } = planResponse.data

    const lineupGroupedByMasterBucket = groupBy(lineup, 'master_description')

    return (
        <div className="content-wrapper-lg footer-container" style={ { gridTemplateRows: 'auto 1fr auto' } }>
            <div className="text-center">
                <h3 className="navy">
                    { planResponse.data.info.company_name }
                </h3>
            </div>
            <div>
                {
                    Object.entries(lineupGroupedByMasterBucket)
                        .map(
                            ([ title, funds ]) => <FundList
                                key={ title }
                                title={ title }
                                funds={ funds }
                            />
                        )
                }
            </div>
            <div>
                { mapDisclaimersToHTML(lineupDisclaimersResponse.data.fileText) }
            </div>
        </div>
    )
}

export function SelectedLineup(props) {
    const [ showRationale, setShowRationale ] = useState(false)
    const params = useParams()
    const planResponse = useGetPlanByIdQuery(params.planId)
    const lineupDisclaimersResponse = useLineupDisclaimersQuery(params.planId)

    if (planResponse.isLoading)
        return <h3 className="teal">Loading...</h3>

    if (planResponse.isError)
        return <h3 className="navy">Error fetching lineup</h3>

    const { lineup } = planResponse.data
    const includedLineup = lineup
        .filter(fund => fund.include)

    const lineupGroupedByMasterBucket = groupBy(includedLineup, 'master_description')

    return (
        <Fragment>
            {
                showRationale ?
                    null :
                    (
                        <div className="content-wrapper-lg">
                            <div className="text-center">
                                <h3 className="navy">Selected Lineup</h3>
                                <p>
                                    Stadion selects a sub-set of investments from the plan's lineup<br />
                                    to construct participant allocations.
                                </p>
                            </div>
                            <h4 className="navy">
                                { planResponse.data.info.company_name }
                            </h4>
                            {
                                Object.entries(lineupGroupedByMasterBucket)
                                    .map(
                                        ([ title, funds ]) => <FundList
                                            key={ title }
                                            title={ title }
                                            funds={ funds }
                                        />
                                    )
                            }
                        </div>
                    )
            }
            <section className="fund-selection-accordion">
                <div className="content-wrapper-lg">
                    <button
                        className={ `accordion ${showRationale ? 'active' : ''}` }
                        onClick={ () => setShowRationale(!showRationale) }
                    >
                        <h4 className="border-bottom">Fund Selection Rationale <span></span></h4></button>
            
                    <div className="panel" style={ { display: showRationale ? 'block': '' } }>
                        <div className="text-center">
                            <p className="large">Stadion uses the S&amp;P Target Date Index allocations to select<br />
                            a sub-set of funds from the plan lineup.</p>
                        </div>
                    
                        <div className="fund-selection-box">
                            <div className="flex-row row-space-between">
                                <div className="flex-40">
                                    <h4 className="navy">Equity</h4>
                                    <table className="two-column">
                                        <tbody>
                                            <tr>
                                                <th>Asset Class</th>
                                                <th className="center">S&amp;P Target Date Index Allocation</th>
                                            </tr>
                                            <tr>
                                                <td className="two-thirds"><span className="dot dot--dark-green"></span> Large Cap</td>
                                                <td className="one-third text-center">50%</td>
                                            </tr>
                                            <tr>
                                                <td className="two-thirds"><span className="dot dot--forrest-green"></span> Small/Mid Cap</td>
                                                <td className="one-third text-center">15%</td>
                                            </tr>
                                            <tr>
                                                <td className="two-thirds"><span className="dot dot--light-green"></span> Developed Markets</td>
                                                <td className="one-third text-center">30%</td>
                                            </tr>
                                            <tr>
                                                <td className="two-thirds"><span className="dot dot--light-green"></span> Emerging Markets</td>
                                                <td className="one-third text-center">5%</td>
                                            </tr>
                                        </tbody>
                                    </table>
            
                                    <h4 className="navy">Fixed Income</h4>
                                    <table className="two-column">
                                        <tbody>
                                            <tr>
                                                <td className="two-thirds"><span className="dot dot--navy"></span> Aggregate</td>
                                                <td className="one-third text-center">65%</td>
                                            </tr>
                                            <tr>
                                                <td className="two-thirds"><span className="dot dot--light-purple"></span> High Yield</td>
                                                <td className="one-third text-center">10%</td>
                                            </tr>
                                            <tr>
                                                <td className="two-thirds"><span className="dot dot--med-blue"></span> TIPS</td>
                                                <td className="one-third text-center">10%</td>
                                            </tr>
                                            <tr>
                                                <td className="two-thirds"><span className="dot dot--pale-blue"></span> Short Term</td>
                                                <td className="one-third text-center">15%</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
            
                                <div className="flex-20">
                                    <div className="text-center">
                                        <p className="small-bold navy text-center">Equity</p>
                                        <ChartEquityImg alt="Equity Graph" />
                        
                                        <p className="small-bold navy text-center">Fixed Income</p>
                                        <FixedIncomeImg alt="Fixed Income Graph" />
                                    </div>
                                </div>
                    
                                <div className="flex-30">
                                    <p className="large">
                                        Stadion's methodology can adapt to individual plan lineups. If a sub-asset class is not available, Stadion will distribute its allocation across the other selected funds from
                                        that asset class.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <p className="small">
                        { mapDisclaimersToHTML(lineupDisclaimersResponse.data.fileText) }
                    </p>   
                </div>
            </section>
        </Fragment>
    )
}

function FundList(props) {
    const showIncludedFundsOnly = useSelector(state => state.lineupPage.showIncludedFundsOnly)
    const { title, funds } = props

    const includedFunds = funds
        .filter(fund => fund.include)

    const fundsToDisplay = showIncludedFundsOnly ?
        includedFunds : funds

    return (
        <Fragment>
            <span className="teal-bold">
                { title }
            </span>
            <table className="four-column">
                <tbody>
                    <tr>
                        <th className="md">Asset Class</th>
                        <th className="lg">Name</th>
                        <th className="sm center">Active/Passive</th>
                        <th className="sm center">Expense Ratio</th>
                    </tr>
                    {
                        funds
                            .map((fund, index) => <Fund
                                key={ fund.sec_id }
                                fund={ fund }
                                odd={ Boolean(index % 2) }
                                hide={ !fundsToDisplay.includes(fund) }
                            />)
                    }
                </tbody>
            </table>
        </Fragment>
    )
}

function Fund(props) {
    const { fund, odd } = props

    const oddClass = odd ? 'odd' : ''
    // const includedClass = fund.include ? 'included' : ''
    const includedClass = props.hide ? '' : 'included'

    return (
        <tr className={ `${includedClass} ${oddClass}` }>
            <td className="md">
                <span className="dot" style={ { backgroundColor: fund.color }}></span>
                { fund.description }
            </td>
            <td className="lg">
                { fund.fund_name }
            </td>
            <td className="sm center">
                { fund.active }
            </td>
            <td className="sm center">
                { fund.exp_ratio }%
            </td>
        </tr>
    )
}

export function Model(props) {
    const location = useLocation()
    const query = extractQuery(location)
    const [ ptile, setPtile ] = useState(query.ptile ? Number(query.ptile) : null)
    const [ ptiles, setPtiles ] = useState(null)
    const [ adjustmentParticipantAge, setAdjustmentParticipantAge ] = useState(50)
    // const [ year, setYear ] = useState(query.ret_year ? Number(query.ret_year) : null)
    const [ year, setYear ] = useState(retirementAge - adjustmentParticipantAge)
    const [ years, setYears ] = useState(null)
    const [ groupByParent, setGroupByParent ] = useState(false)
    const [ showAdjustments, setShowAdjustments ] = useState(false)
    // const [ adjustmentParticipantAge, setAdjustmentParticipantAge ] = useState(0)
    const [ adjustmentSalary, setAdjustmentSalary ] = useState(150000)
    const [ adjustmentOutsideAssets, setAdjustmentOutsideAssets ] = useState(100000)
    const [ adjustmentContributionRate, setAdjustmentContributionRate ] = useState(5)
    const [ adjustmentAccountValue, setAdjustmentAccountValue ] = useState(250000)
    const params = useParams()
    const planResponse = useGetPlanByIdQuery(params.planId)
    const ptileResponse = useGetPtileForAdjustmentsQuery({
        planId: params.planId,
        adjustmentParticipantAge,
        adjustmentSalary,
        adjustmentOutsideAssets,
        adjustmentAccountValue,
        adjustmentContributionRate
    })
    const lineupDisclaimersResponse = useLineupDisclaimersQuery(params.planId)

    useEffect(() => {
        setYear(retirementAge - adjustmentParticipantAge)
    }, [ adjustmentParticipantAge ])

    useEffect(() => {
        if (ptiles === null && !planResponse.isLoading && !planResponse.isError) {
            const ptiles = Object
                .keys(model)
                .map(ptile => Number(ptile))

            setPtiles(ptiles)
        }

        if (ptile === null && ptiles !== null) {
            if (ptiles.includes(50))
                setPtile(50)
            else
                setPtile(ptiles[Math.floor(ptiles.length / 2)])
        }

        if (years === null && !planResponse.isLoading && !planResponse.isError) {
            const { model } = planResponse.data
            const years = Object
                .keys(Object.values(model)[0])
                .map(Number)
                // .filter(year => year % 5 === 0)
                .sort()
                .reverse()

            setYears(years)
        }

        if (year === null && years !== null)
            setYear(years[35]) // setting age to 50, years not sorted correctly - sorted as strings
            // setYear(years[0])
    })

    useEffect(() => {
        if (ptileResponse.data)
            setPtile(ptileResponse.data.ptile)
    }, [
        adjustmentParticipantAge,
        adjustmentSalary,
        adjustmentAccountValue,
        adjustmentContributionRate,
        adjustmentOutsideAssets,
        ptileResponse.data
    ])

    if (planResponse.isLoading || lineupDisclaimersResponse.isLoading)
        return <span>Loading...</span>

    if (planResponse.isError || lineupDisclaimersResponse.isError)
        return <span>Error fetching lineup</span>

    const { model } = planResponse.data

    if (ptiles === null || ptile === null)
        return <span>Loading...</span>

    const funds = groupByParent ?
        Object.entries(groupBy(model[ptile][year], 'parent'))
            .reduce((funds, entry) => {
                const [ parent, parentFunds ] = entry
                const percentage = parentFunds.reduce((percentage, fund) => percentage + fund.percentage, 0)
                // const exp_ratio = parentFunds.reduce((exp_ratio, fund) => exp_ratio + (fund.percentage / 100 * fund.exp_ratio), 0)
                const { color } = parentFunds[0]

                return [
                    ...funds,
                    {
                        parent,
                        percentage,
                        // exp_ratio,
                        color
                    }
                ]
            }, []) :
        model[ptile][year]

    const eqPercentage = funds
        .reduce((eqPercentage, fund) => fund.master_bucket === 'Equity' ? eqPercentage + fund.percentage : eqPercentage, 0)
        
    const fiPercentage = funds
        .reduce((fiPercentage, fund) => fund.master_bucket === 'Fixed Income' ? fiPercentage + fund.percentage : fiPercentage, 0)

    const options = {
        chart: {
            type: 'pie',
            backgroundColor: 'transparent',
            height: 220
        },
        title: {
            text: ''
        },
        plotOptions: {
            pie: {
                shadow: false,
                dataLabels: {
                    enabled: false,
                }
            }
        },
        tooltip: {
            formatter: function() {
                return `${this.key}: ${this.y}%`
            }
        },
        series: [
            {
                name: 'Funds',
                data: funds
                    .map(fund => ({
                        name: groupByParent ? fund.parent : fund.fund_name,
                        y: fund.percentage,
                        color: groupByParent ? (fund.parent_color || fund.color) : fund.color
                    })),
                size: '100%',
                innerSize: '80%'
            }
        ]
    }
    const accExpRatio = funds
        .reduce((accExpRatio, fund) => accExpRatio + (fund.percentage / 100 * fund.exp_ratio), 0)

    return (
        <div className="content-wrapper-lg footer-container" style={ { gridTemplateRows: '1fr auto' } }>
            <div className="flex-row row-space-between">
                <div className="flex-60">
                    <h4 className="navy">
                        Sample Participant Holdings
                    </h4>
                    <table className="four-column">
                        <tbody>
                            <tr>
                                <th className="md">Asset Class</th>
                                <th className="lg">Name</th>
                                <th className="sm center">Allocation Percentage</th>
                                <th className="sm center">Expense Ratio</th>
                            </tr>
                            {
                                funds
                                    .map((fund, index) => {
                                        const oddClass = Boolean(index % 2) ? 'odd' : ''
                                        const parentClass = groupByParent ? 'parent-row' : ''

                                        return (
                                            <tr key={ fund.sec_id || fund.fund_name || fund.parent } className={ `${oddClass} ${parentClass}` }>
                                                <td className="md">
                                                    <span className="dot" style={ { backgroundColor: groupByParent ? (fund.parent_color || fund.color) : fund.color } }></span>
                                                    {
                                                        groupByParent ? null :
                                                        <span className="description">
                                                            { fund.description }
                                                        </span>
                                                    }
                                                </td>
                                                <td className="lg">
                                                    { groupByParent ? fund.parent : fund.fund_name }
                                                </td>
                                                <td className="sm center">
                                                    { fund.percentage }%
                                                </td>
                                                <td className="sm center">
                                                    {
                                                        groupByParent ?
                                                            null :
                                                            <span className="exp_ratio">
                                                                { (Math.round(fund.exp_ratio * 100) / 100).toFixed(2) }
                                                            </span>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th className="md"></th>
                                    <th className="lg">Weighted Average Fund Expense</th>
                                    <th className="sm center"></th>
                                    <th className="sm center">{ (Math.round(accExpRatio * 100) / 100).toFixed(2) }%</th>
                                </tr>
                            </tfoot>
                    </table>
                </div>
                <div className="flex-40">
                    <div className="p2-l">
                        <h4 className="navy">Sample Participant Allocations</h4>
                        <div className="model-chart" style={ { opacity: ptileResponse.isFetching ? 0.6 : 1 } }>
                            <HighchartsReact
                                highcharts={ Highcharts }
                                options={ options }
                            />
                            <div id="model-chart-label">
                                {
                                    ptileResponse.isFetching ?
                                    <span className="primary caption">Loading</span> :
                                    <Fragment>
                                        <p>
                                            <span className="primary caption">{ eqPercentage }%</span>
                                            <br />
                                            <span className="secondary-dark caption">Equity</span>
                                        </p>
                                        <p>
                                            <span className="primary caption">{ fiPercentage }%</span>
                                            <br />
                                            <span className="secondary-dark caption">Fixed Income</span>
                                        </p>
                                    </Fragment>
                                }
                            </div>
                        </div>
                        <section className="sample-allocation-accordion">
                            <button className="accordion" onClick={ () => setShowAdjustments(!showAdjustments) }>
                                <h4 className="navy">Sample Adjustments</h4>
                            </button>
                            <div className="panel" style={ { display: showAdjustments ? 'block' : '' } }>
                                <form>
                                    <h5 className="navy mt-1">Participant Age:</h5>
                                    <div className="radio-row border-gray-bottom" onChange={ e => setAdjustmentParticipantAge(parseInt(e.target.value)) }>
                                        <span>
                                            <input type="radio" disabled={ ptileResponse.isLoading } id="age1" name="age" value={ 25 } checked={ adjustmentParticipantAge === 25 } />
                                            <label htmlFor="age1">25</label>
                                        </span>
                                        <span>
                                            <input type="radio" disabled={ ptileResponse.isLoading } id="age2" name="age" value={ 50 } checked={ adjustmentParticipantAge === 50 } />
                                            <label htmlFor="age2">50</label>
                                        </span>
                                        <span>
                                            <input type="radio" disabled={ ptileResponse.isLoading } id="age3" name="age" value={ 67 } checked={ adjustmentParticipantAge === 67 } />
                                            <label htmlFor="age3">67</label>
                                        </span>
                                    </div>

                                    <h5 className="navy mt-1">Salary:</h5>
                                    <div className="radio-row border-gray-bottom" onChange={ e => setAdjustmentSalary(parseInt(e.target.value)) }>
                                        <span>
                                            <input type="radio" disabled={ ptileResponse.isLoading } id="salary1" name="salary" value={ 50000 } checked={ adjustmentSalary === 50000 } />
                                            <label htmlFor="salary1">$50,000</label>
                                        </span>
                                        <span>
                                            <input type="radio" disabled={ ptileResponse.isLoading } id="salary2" name="salary" value={ 150000 } checked={ adjustmentSalary === 150000 } />
                                            <label htmlFor="salary2">$150,000</label> 
                                        </span>
                                        <span>
                                            <input type="radio" disabled={ ptileResponse.isLoading } id="salary3" name="salary" value={ 300000 } checked={ adjustmentSalary === 300000 } />
                                            <label htmlFor="salary3">$300,000</label>
                                        </span>
                                    </div>

                                    <h5 className="navy mt-1">Account Value:</h5>
                                    <div className="radio-row border-gray-bottom" onChange={ e => setAdjustmentAccountValue(parseInt(e.target.value)) }>
                                        <span>
                                            <input type="radio" disabled={ ptileResponse.isLoading } id="accountValue1" name="accountValue" value={ 25000 } checked={ adjustmentAccountValue === 25000 } />
                                            <label htmlFor="accountValue1">$25,000</label>
                                        </span>
                                        <span>
                                            <input type="radio" disabled={ ptileResponse.isLoading } id="accountValue2" name="accountValue" value={ 250000 } checked={ adjustmentAccountValue === 250000 } />
                                            <label htmlFor="accountValue2">$250,000</label> 
                                        </span>
                                        <span>
                                            <input type="radio" disabled={ ptileResponse.isLoading } id="accountValue3" name="accountValue" value={ 750000 } checked={ adjustmentAccountValue === 750000 } />
                                            <label htmlFor="accountValue3">$750,000</label>
                                        </span>
                                    </div>

                                    <h5 className="navy mt-1">Contribution Rate:</h5>
                                    <div className="radio-row border-gray-bottom" onChange={ e => setAdjustmentContributionRate(parseInt(e.target.value)) }>
                                        <span>
                                            <input type="radio" disabled={ ptileResponse.isLoading } id="rate1" name="rate" value={ 3 } checked={ adjustmentContributionRate === 3 } />
                                            <label htmlFor="rate1">3%</label>
                                        </span>
                                        <span>
                                            <input type="radio" disabled={ ptileResponse.isLoading } id="rate2" name="rate" value={ 5 } checked={ adjustmentContributionRate === 5 } />
                                            <label htmlFor="rate2">5%</label> 
                                        </span>
                                        <span>
                                            <input type="radio" disabled={ ptileResponse.isLoading } id="rate3" name="rate" value={ 10 } checked={ adjustmentContributionRate === 10 } />
                                            <label htmlFor="rate3">10%</label>
                                        </span>
                                    </div>

                                    <h5 className="navy mt-1">Outside Assets:</h5>
                                    <div className="radio-row " onChange={ e => setAdjustmentOutsideAssets(parseInt(e.target.value)) }>
                                        <span>
                                            <input type="radio" disabled={ ptileResponse.isLoading } id="outsideAssets1" name="outsideAssets" value={ 0 }  checked={ adjustmentOutsideAssets === 0 } />
                                            <label htmlFor="outsideAssets1">$0</label>
                                        </span>
                                        <span>
                                            <input type="radio" disabled={ ptileResponse.isLoading } id="outsideAssets2" name="outsideAssets" value={ 100000 }  checked={ adjustmentOutsideAssets === 100000 } />
                                            <label htmlFor="outsideAssets2">$100,000</label> 
                                        </span>
                                        <span>
                                            <input type="radio" disabled={ ptileResponse.isLoading } id="outsideAssets3" name="outsideAssets" value={ 500000 }  checked={ adjustmentOutsideAssets === 500000 } />
                                            <label htmlFor="outsideAssets3">$500,000</label>
                                        </span>
                                    </div>
                                </form>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <p>
                { mapDisclaimersToHTML(lineupDisclaimersResponse.data.fileText) }
            </p>
            {/* <div id="model-chart-column">
                <div id="model-chart-container">
                    <div id="model-range-input-container">
                        <div className="range-input-group">
                            <div className="slider-area">
                                <span className="secondary-dark caption">Conservative</span>
                                <input
                                    className={ inputRangeClass() }
                                    type="range"
                                    min={ 0 }
                                    max={ 100 }
                                    onChange={ e => setPtile(findClosest(ptiles, Number(e.target.value))) }
                                    value={ ptile }
                                />
                                <span className="secondary-dark caption">Aggressive</span>
                            </div>
                        </div>
                        <div className="range-input-group">
                            <div className="slider-area">
                                <span className="secondary-dark caption">Growth</span>
                                <input
                                    type="range"
                                    style={ { direction: 'rtl' } }
                                    min={ -10 }
                                    max={ 40 }
                                    onChange={ e => setYear(findClosest(years, Number(e.target.value))) }
                                    value={ year }
                                />
                                <span className="secondary-dark caption">Retirement</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="model-table-column">
                <ul id="model-list">
                    <li className="odd header-row">
                        <span className="md-icon-button" onClick={ () => setGroupByParent(!groupByParent) }>
                            <i className={`material-icons md-toggle_${groupByParent ? 'on' : 'off'} ${groupByParent ? 'primary' : 'secondary'}` }>
                            </i>
                            <span className="label">
                                Group by Parent
                            </span>
                        </span>
                        <button onClick={ () => xlsx(mapModelToXSLX(model), { fileName: planResponse.data.info.company_name }) }>
                            <i className="material-icons md-file_download primary">
                            </i>
                            Download Model Data
                        </button>
                    </li>
                    {
                        funds
                            .map((fund, index) => {
                                const oddClass = Boolean(index % 2) ? 'odd' : ''
                                const parentClass = groupByParent ? 'parent-row' : ''

                                return (
                                    <li key={ fund.sec_id || fund.fund_name || fund.parent } className={ `${oddClass} ${parentClass}` }>
                                        <div className="color" style={ { backgroundColor: groupByParent ? (fund.parent_color || fund.color) : fund.color } }></div>
                                        {
                                            groupByParent ? null :
                                            <span className="description">
                                                { fund.plan_map_id }
                                            </span>
                                        }
                                        <span className="fund-name">
                                            { groupByParent ? fund.parent : fund.fund_name }
                                        </span>
                                        <span className="percentage">
                                            { fund.percentage }%
                                        </span>
                                        {
                                            groupByParent ?
                                                null :
                                                <span className="exp_ratio">
                                                    { Math.round(fund.exp_ratio * 100) / 100 }
                                                </span>
                                        }
                                        
                                    </li>
                                )
                            })
                    }
                </ul>
            </div> */}
        </div>
    )
}

// function findClosest(ptiles, value) {
//     if (ptiles.includes(value))
//         return value
    
//     const ptileDiff = sortBy(ptiles
//         .map(ptile => ({ ptile, diff: Math.abs(ptile - value) })), 'diff')

//     return ptileDiff[0].ptile
// }

// function mapModelToXSLX(model) {
//     return Object.entries(model)
//         .map(entry => {
//             const [ ptile, fundsByYear ] = entry

//             const years = Object
//                 .keys(fundsByYear)
//                 .map(Number)
//                 .filter(year => year >= -10 && year <= 40 && year % 10 === 0)
//                 .sort((a, b) => a > b ? 1 : -1)
//                 .reverse()

//             const content = fundsByYear[years[0]]
//                 .map(fund => {
//                     return {
//                         ...fund,
//                         ...years.reduce((p, y) => {
//                             return {
//                                 ...p,
//                                 [y]: fundsByYear[y]
//                                     .find(f => f.sec_id === fund.sec_id)
//                                     .percentage
//                             }
//                         }, {})
//                     }
//                 })

//             return {
//                 sheet: `Percentile ${ptile}`,
//                 columns: [
//                     { label: 'Asset Class', value: 'description' },
//                     { label: 'Fund', value: 'fund_name' },
//                     { label: 'Ticker', value: 'ticker' },
//                     { label: 'Expense Ratio', value: 'exp_ratio' },
//                     ...years.map(year => {
//                         return {
//                             label: `TD ${moment().year() - 1 + year}`,
//                             value: row => row[year]
//                         }
//                     })
                    
//                 ],
//                 content
//             }
//         })
// }

export function Glidepaths(props) {
    const navigate = useNavigate()
    const params = useParams()
    const planResponse = useGetPlanByIdQuery(params.planId)
    const spIndexResponse = useGetSPIndexGlidepathQuery()

    if (planResponse.isLoading || spIndexResponse.isLoading)
        return <span>Loading...</span>

    if (planResponse.isError || spIndexResponse.isError)
        return <span>Error fetching lineup</span>

    const { glidepath, info } = planResponse.data
    const spIndex = spIndexResponse.data

    const series = Object
        .entries(glidepath)
        .reverse()
        .map((entry, index) => {
            const [ ptile, dataPoints ] = entry

            return {
                color: dataPoints[0] ? dataPoints[0].color : 'gray',
                legendName: `${info.path_name} Path ${index + 1}`,
                type: 'line',
                name: `Percentile ${ptile}`,
                data: dataPoints.map(dp => ({
                    x: dp.ret_year,
                    y: dp.eq,
                    color: dp.color,
                    marker: {
                        enabled: true,
                        radius: 4,
                        symbol: 'circle'
                    },
                    clickInfo: {
                        ptile: dp.ptile,
                        retYear: dp.ret_year
                    }
                })),
                lineWidth: 0,
                cursor: 'pointer',
                point: {
                    events: {
                        click: function() {
                            const { clickInfo } = this
                            navigate(`/lineup/${params.planId}/model?ptile=${clickInfo.ptile}&ret_year=${clickInfo.retYear}`)
                        }
                    }
                }
            }
        })
        .concat({
            type: 'line',
            name: 'SP Index',
            color: '#000000',
            legendName: () => <span>S&amp;P Target Date Index<sup>2</sup></span>,
            lineWidth: 6,
            data: spIndex
                .data[0]
                .glidepath.map(dp => {
                    return {
                        y: dp.eq,
                        x: dp.ret_year,
                        marker: {
                            enabled: false,
                            symbol: 'circle'
                        }
                    }
                })
        })

    return (
        <Fragment>
            <div className="content-wrapper-lg">
                <div className="text-center">
                    <h3 className="navy">Participant allocations</h3>
                    <p>Stadion provides many allocations for participants based on individual factors.</p>
                </div>
                <div className="p2-tb text-left">
                    <h4 className="navy">{info.path_name} Glide Paths<sup>1</sup> vs. S&amp;P Target Date Index</h4>
                    <div className="flex-row row-space-between">
                        <div className="flex-75">
                            <HighchartsReact
                                highcharts={ Highcharts }
                                options={ 
                                    {
                                        chart: {
                                            type: 'line',
                                            height: 500
                                        },
                                        title: {
                                            text: ''
                                        },
                                        legend: {
                                            enabled: false,
                                        },
                                        xAxis: {
                                            reversed: true,
                                            title: {
                                                text: 'Years to/from retirement',
                                                style: {
                                                    fontWeight: 600,
                                                    fontSize: '1.5rem',
                                                    color: '#000000',
                                                    lineHeight: '2.7rem',
                                                    letterSpacing: 0
                                                }
                                            },
                                            tickInterval: 5,
                                            tickWidth: 0
                                        },
                                        yAxis: {
                                            title: {
                                                text: 'Equity allocation (%)',
                                                style: {
                                                    fontWeight: 600,
                                                    fontSize: '1.5rem',
                                                    color: '#000000',
                                                    lineHeight: '2.7rem',
                                                    letterSpacing: 0
                                                }
                                            },
                                            min: 0,
                                            max: 100,
                                            tickInterval: 10,
                                            tickWidth: 1,
                                            lineWidth: 1
                                        },
                                        tooltip: {
                                            formatter: function() {
                                                return `${this.series.name}: ${Math.round(this.y * 100) / 100}% Equity` }
                                        },
                                        series
                                    }
                                }
                            />
                        </div>
                        <div className="flex-25">
                            <div className="pl-1">
                                <ul className="key">
                                    {
                                        series.map(s => <li key={ s.series_name }>
                                            <span className={ `dot-small ${typeof s.legendName !== 'string' ? 'sp' : ''}` } style={ { background: s.color } }></span>
                                            { typeof s.legendName === 'string' ? s.legendName : s.legendName() }
                                        </li>)
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <p className="small">
                        1 The TargetPro glide path lines represent sample equity allocations for Stadion’s managed account service.
                        Actual allocations will vary due to market conditions and other factors. Glide paths assume retirement age of 67.
                        Investments are subject to risk and may lose value.
                    </p>
                    <p className="small">
                        2 The S&amp;P Target Date Index glide path was created by Stadion 
                        by combining the equity allocations of S&amp;P Target Date Index vintages. To create a smooth glide path, Stadion assumes the difference in equity 
                        allocation percentages between target date vintages will adjust equally on an average basis each year. For example if there is a 10% equity 
                        allocation difference between a 2030 vintage and 2040 vintage, the equity allocation will adjust 1% each year.
                    </p>
                </div>
            </div>
        </Fragment>
    )
}

function extractQuery(location) {
    return location
        .search
        .replace('?', '')
        .split('&')
        .reduce((query, entry) => {
            const [ key, value ] = entry.split('=')

            return {
                ...query,
                [key]: value
            }
        }, {})
}

export function Performance() {
    const { attendees } = useSelector(state => state.attendees)
    const [ ranges, setRanges ] = useState(null)
    const [ period, setPeriod ] = useState(null)
    // const [ showIndustryPerformance, setShowIndustryPerformance ] = useState(true)
    const [ showSPPerformance, setShowSPPerformance ] = useState(true)
    const [ showPlanPerformance, setShowPlanPerformance ] = useState(true)
    // const [ visibleDetailedSeries, setVisibleDetailedSeries ] = useState([])
    const [ category, setCategory ] = useState(null)
    const params = useParams()
    const disclaimersResponse = useGetDisclaimersQuery({ planId: params.planId, emails: attendees.map(attendee => attendee.email) })
    const performanceRangesResponse = useGetPerformanceRangesQuery(params.planId)
    const performanceResponse = useGetPerformanceByPlanIdQuery({ planId: params.planId, period }, { skip: !Boolean(period) })
    const spIndexPerformanceResponse = useGetSPIndexPerformanceQuery(params.planId)
    // const industryPerformanceResponse = useGetIndustryPerformanceQuery({ period, planId: params.planId }, { skip: !Boolean(period) })
    // const detailedIndustryPerformanceResponse = useGetIndustryPerformanceQuery({ period, detailed: true, planId: params.planId }, { skip: !Boolean(period) })
    const planResponse = useGetPlanByIdQuery(params.planId)
    const performanceDisclaimersResponse = usePerformanceDisclaimersQuery(params.planId)

    useEffect(() => {
        if (!performanceRangesResponse.isLoading && !ranges)
            setRanges(performanceRangesResponse.data)

        if (!period && ranges) {
            const periodSI = ranges.find(r => r.period === 'SI')
            const [ rangeToSet ] = ranges.filter(p => p.spValid && p.startDate >= periodSI.startDate)

            setPeriod(rangeToSet.period)
        }
    })

    if (disclaimersResponse.isLoading)
        return <p>Sending disclaimers...</p>

    if (disclaimersResponse.isError)
        return <p>Error happened while sending disclaimers. Please try again or contact IT</p>

    if (window.innerWidth < 800)
        return <span>To be able to view the chart, please use the screen at least 800 px wide</span>

    if (
        performanceResponse.isLoading ||
        spIndexPerformanceResponse.isLoading ||
        planResponse.isLoading ||
        performanceRangesResponse.isLoading ||
        // industryPerformanceResponse.isLoading ||
        // detailedIndustryPerformanceResponse.isLoading ||
        performanceDisclaimersResponse.isLoading ||
        !ranges ||
        !period
    )
        return <p>Loading...</p>

    if (performanceResponse.isError ||
        spIndexPerformanceResponse.isError ||
        planResponse.isError ||
        performanceDisclaimersResponse.isError ||
        performanceRangesResponse.isError
        // industryPerformanceResponse.isError ||
        // detailedIndustryPerformanceResponse.isError
    )
        return <span>Error fetching plan performance</span>

    const { data } = performanceResponse
    const ptileColors = Object.entries(planResponse.data.glidepath)
        .reduce((colors, entry) => {
            const [ ptile, entries ] = entry

            return {
                ...colors,
                [ptile]: entries[0].color
            }
        }, {})
        
    const dataForPeriod = data[period]
    const [ firstRawSeries ] = Object.values(dataForPeriod)

    const categories = sortBy(firstRawSeries, 'year')
        .reverse()
        .map(dp => dp.category)

    const spIndexSeries = {
        name: 'SP Index',
        type: 'line',
        color: '#000000',
        lineWidth: 8,
        data: categories.map(category => {
            const spPoint = spIndexPerformanceResponse.data[period].find(dp => dp.category === category)

            return spPoint ? spPoint.value : null
        }),
        visible: showSPPerformance
    }

    // const industrySeries = {
    //     name: 'Industry',
    //     color: '#bdbdbd',
    //     type: 'arearange',
    //     zIndex: -1,
    //     data: categories.map(category => {
    //         const dp = industryPerformanceResponse.data.find(dp => dp.category === category)
            
    //         return (dp && !industryPerformanceResponse.isFetching) ? [ dp.min, dp.max ] : [ null, null ]
    //     }),
    //     visible: showIndustryPerformance
    // }

    const planSeries = Object.entries(data[period])
        .map(entry => {
            const [ ptile, points ] = entry

            return {
                name: `Percentile ${ptile}`,
                type: 'line',
                data: categories.map(category => points.find(dp => dp.category === category).perf),
                color: ptileColors[ptile],
                visible: showPlanPerformance,
                lineWidth: 2
            }
        })

    // const detailedIndustrySeries = detailedIndustryPerformanceResponse.data
    //     .filter(series => visibleDetailedSeries.find(s => s.id === series.id))
    //     .map(series => {
    //         const dataGrouped = groupBy(series.data, 'category')
    //         const visibleEntry = visibleDetailedSeries
    //             .find(s => s.id === series.id)
            
    //         return {
    //             id: series.id,
    //             name: series.series_name,
    //             type: 'line',
    //             color: series.color,
    //             data: categories.map(c => {
    //                 const [ dp = null ] = dataGrouped[c] || []

    //                 return [ c, dp ? dp && dp.perf : dp ]
    //             }),
    //             lineWidth: 6,
    //             visible: visibleEntry ? visibleEntry.visible : false 
    //         }
    //     })

    const series = [
        ...planSeries,
        spIndexSeries,
        // industrySeries,
        // ...detailedIndustrySeries
    ]

    const categorySeries = category ? sortBy(series
        .filter(s => s.type !== 'arearange')
        .map(s => {
            const dp = s.data[categories.indexOf(category)]

            return {
                data: [ dp ],
                dp,
                name: s.name,
                color: s.color,
                type: 'bar'
            }
        }), 'dp')
        : null

    return (
        <div className="content-wrapper-lg">
            <div className="text-left">
                <p>Hypothetical performance as of { ranges.find(r => r.period === period).endDate }</p>
                <h4 className="navy">{ planResponse.data.info.company_name }</h4>
            </div>
            <div className="performance-tabs-nav">
                <ul>
                    {
                        ranges.map(range => {
                            const sinceInceptionPeriod = ranges.find(range => range.period === 'SI')
                            const activeClass = period === range.period ? 'active' : ''
                            const disabledClass = (range.startDate < sinceInceptionPeriod.startDate || !range.spValid) ? 'disabled' : ''

                            return (
                                <li
                                    key={ range.period }
                                    title={ `${range.startDate} to ${range.endDate}` }
                                    className={ disabledClass }
                                >
                                    {
                                        disabledClass ?
                                        <a
                                            className={ disabledClass }>
                                            { range.label } { range.period === 'SI' && `(${moment(range.startDate).format('MM/DD/YYYY')})` }
                                        </a> :
                                        <a href=""
                                        className={ `${activeClass} ${disabledClass}` }
                                        onClick={ e => { e.preventDefault(); setPeriod(range.period) } }>
                                            { range.label } { range.period === 'SI' && `(${moment(range.startDate).format('MM/DD/YYYY')})` }
                                        </a>
                                    }
                                </li>
                            )
                        })
                    }
                </ul>
            </div>
            <div className="flex-row performance-tab open">
                <div className="flex-75 text-left">
                    <HighchartsReact
                        highcharts={ Highcharts }
                        options={
                            {
                                chart: {
                                    type: 'line',
                                    height: Math.round(window.innerHeight - 275)
                                    // height: 500
                                },
                                title: {
                                    text: ''
                                },
                                legend: {
                                    enabled: false
                                },
                                xAxis: {
                                    categories: categories,
                                    labels: {
                                        enabled: !Boolean(category)
                                    },
                                    title: {
                                        text:  category || 'Vintages'
                                    }                         
                                },
                                yAxis: {
                                    title: {
                                        text: 'Return'
                                    }
                                },
                                plotOptions: {
                                    line: {
                                        marker: {
                                            symbol: 'circle',
                                            radius: 4
                                        },
                                        connectNulls: true,
                                        events: {
                                            click: function(e) {
                                                setCategory(e.point.category)
                                            }
                                        }
                                    },
                                    arearange: {
                                        marker: {
                                            enabled: false
                                        }
                                    }
                                },
                                series: category ? categorySeries : series
                            }
                        }
                    />
                </div>
                <div className="flex-25">nge
                    <div className="pl-1">
                        <div className="graph-control">
                            {/* <AddSeriesForm
                                detailedIndustryPerformanceResponse={ detailedIndustryPerformanceResponse }
                                visibleDetailedSeries={ visibleDetailedSeries }
                                setVisibleDetailedSeries={ setVisibleDetailedSeries }
                            > */}
                                <div className="p1-tb flex-row align-center">
                                    <div className="flex-65">
                                        <strong>Percentiles</strong>
                                    </div>
                                    <div className="flex-35">
                                        <label className="switch">
                                            <input type="checkbox" checked={ showPlanPerformance } onChange={ () => setShowPlanPerformance(!showPlanPerformance) } />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                <div className="p1-tb flex-row align-center">
                                    <div className="flex-65">
                                        <strong>S&amp;P Target Date Index</strong>
                                    </div>
                                    <div className="flex-35">
                                        <label className="switch">
                                            <input type="checkbox" checked={ showSPPerformance } onChange={ () => setShowSPPerformance(!showSPPerformance) } />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                {/* <div className="p1-tb flex-row align-center">
                                    <div className="flex-65">
                                        <strong>Industry Range</strong>
                                    </div>
                                    <div className="flex-35">
                                        <label className="switch">
                                            <input type="checkbox" checked={ showIndustryPerformance } onChange={ () => setShowIndustryPerformance(!showIndustryPerformance) } />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                </div> */}
                            {/* </AddSeriesForm> */}
                        </div>
                        {/* <button onClick={ () => xlsx(mapPerformanceToXlsx([ ...series, ...detailedIndustryPerformanceResponse.data ], categories), { fileName: planResponse.data.info.company_name }) }>
                            <i className="material-icons md-file_download primary">
                            </i>
                            Download Performance Data
                        </button> */}
                        {/* <ul className="key">
                            {
                                detailedIndustrySeries
                                    .map(series => {
                                        const handler = () => {
                                            const newSeriesList = visibleDetailedSeries
                                                .map(s => {
                                                    if (s.id !== series.id)
                                                        return s
                                                    else
                                                        return {
                                                            ...s,
                                                            visible: !s.visible
                                                        }
                                                })

                                            setVisibleDetailedSeries(newSeriesList)
                                        }

                                        const isVisible = visibleDetailedSeries
                                            .find(s => s.id === series.id)
                                            .visible

                                        return (
                                            <li onClick={ handler } className="clickable">
                                                <span className="dot" style={ { backgroundColor: isVisible ? series.color : '' } }>
                                                </span>
                                                { series.name }
                                            </li>
                                        )
                                    })
                            }
                        </ul> */}
                        {
                            Boolean(category) &&
                            <button id="reset-vintage-button" className="" onClick={ () => setCategory(null) }>
                                <i className="material-icons md-clear primary">
                                </i>
                                Reset Vintage { category }
                            </button>
                        }
                    </div>
                </div>
            </div>
            <div>
                <p className="small">
                    Expense ratios and performance shown for investments do not include recordkeeper-specific fees which would increase the expense for each investment.
                </p>
                
                <p className="small">
                    The information in this presentation is for informational purposes only and does not constitute an offer to sell, a solicitation of an offer to buy, or a recommendation of any 
                    security that may be referenced in this presentation. It should not be assumed that any investment recommendations or decisions Stadion makes in the future will be profitable. 
                    Stadion reserves the right to modify its current investment strategies and techniques based on changing market dynamics or client needs.
                </p>
            
                <p className="small">
                    The S&amp;P Target Date Index Series is composed of eleven multi-asset class indices, each corresponding to a particular target retirement date. The asset class mix for each 
                    index is determined once a year through a process designed to reflect the overall investment opportunity of the represented markets. Each index is fully investable, 
                    with varying levels of exposure to equities and fixed income. To create its target date benchmark, S&amp;P Indices conducts an annual survey of target date funds each year in April. 
                    Funds are required by the SEC to report their holdings quarterly. At the time of the annual index review, it was the most recent quarterly holdings data for each fund in its survey. 
                    The overall process is as follows: (i) Funds are identified as Target Date funds from the Morningstar or Lipper databases and sorted by asset size. Fund families not meeting the 
                    minimum asset threshold of US $100 million are removed from consideration. (ii) Fund holdings are drawn from the latest period available using commercial databases and the SEC's Edgar web site. 
                    (iii) Asset class exposures for the funds are derived by mapping the fund holdings to their corresponding asset class category. In cases where surveyed funds hold balanced or other multi-asset class funds, 
                    S&amp;P Indices look through to the underlying asset allocation of these funds to determine their net effect on the asset allocation of the surveyed fund. It is not possible to invest directly in indexes which 
                    are unmanaged and do not incur fees. Each target date allocation is created and retired according to a pre-determined schedule related to the relevant target date.
                </p>
            
                <p className="small">
                    The S&amp;P &amp;P Target Date 2060 Index is designed to represent a small, style-specific derived consensus of asset class exposure and glide path for target dates to 2060. The index is designed to help defined 
                    contribution plan sponsors screen, select, and monitor appropriate target date funds. The launch date was June 1, 2016. The inception date for the S&amp;P Target Date 2060 Index is not shown due to this data 
                    being measured from the inception date of the lineup rather than the inception date of the S&amp;P Target Date 2060 Index. The S&amp;P &amp;P Target Date 2050 Index (Moderate Glide Path) is designed to represent a 
                    broadly derived consensus of asset class exposures, allocating to equities and fixed income according to a moderate glide path for the target date 2050. The launch date was August 17, 2020. 
                    The inception date for the S&amp;P Target Date 2050 Index is not shown due to this data being measured from the inception date of the lineup rather than the inception date of the S&amp;P Target Date 2050 Index. 
                    The S&amp;P Target Date 2040 Index is designed to represent a broadly derived consensus of asset class exposure and glide path for target date year 2040. The index allocates to equities and fixed income at varying levels, 
                    according to a pre-determined schedule related to the respective target date. The launch date was September 25, 2008. The inception date for the S&amp;P Target Date 2040 Index is not shown due to this data being measured 
                    from the inception date of the lineup rather than the inception date of the S&amp;P Target Date 2040 Index. The S&amp;P Target Date Through 2030 Index is designed to represent a small, style-specific derived consensus of asset 
                    class exposure and glide path for target dates through 2030. The index is designed to help defined contribution plan sponsors screen, select, and monitor appropriate target date funds. The launch date was June 7, 2012 
                    The inception date for the S&amp;P Target Date 2030 Index is not shown due to this data being measured from the inception date of the lineup rather than the inception date of the S&amp;P Target Date 2030 Index. 
                    The S&amp;P Target Date 2020 Index is designed to represent a broadly derived consensus of asset class exposure and glide path for target date year 2020. The index allocates to equities and fixed income at varying levels, 
                    according to a pre-determined schedule related to the respective target date. The launch date was September 25, 2008. The inception date for the S&amp;P Target Date 2020 Index is not shown due to this data being measured 
                    from the inception date of the lineup rather than the inception date of the S&amp;P Target Date 2020 Index.
                </p>
                { mapDisclaimersToHTML(performanceDisclaimersResponse.data.fileText) }
            </div>
        </div>
    )
}

// function AddSeriesForm(props) {
//     const [ seriesName, setSeriesName ] = useState('')

//     const { detailedIndustryPerformanceResponse, visibleDetailedSeries, setVisibleDetailedSeries } = props

//     if (!detailedIndustryPerformanceResponse.data)
//         return null

//     const series = detailedIndustryPerformanceResponse.data

//     function handleSubmit(e) {
//         e.preventDefault()

//         const s = series.find(s => s.series_name === seriesName)

//         setSeriesName('')
//         setVisibleDetailedSeries([ ...visibleDetailedSeries, { visible: true, id: s.id } ])
//     }

//     const buttonDisabled = !Boolean(series.find(s => s.series_name === seriesName))

//     return (
//         <form onSubmit={ handleSubmit }>
//             <label htmlFor="add-series">Add series to chart</label>
//             <br />
//             <div className="flex-row">
//                 <div className="flex-65">
//                     <input
//                         id="add-series"
//                         type="text"
//                         list="series"
//                         value={ seriesName }
//                         onChange={ e => setSeriesName(e.target.value) }
//                     />
//                 </div>
//                 <div className="flex-35">
//                     <input type="submit" id="add-series" disabled={ buttonDisabled } value="Add" />
//                 </div>
//             </div>
//             { props.children }
//             <datalist id="series">
//                 {
//                     series
//                         .map(s => <option key={ s.id }>{ s.series_name }</option>)
//                 }
//             </datalist>
//         </form>
//     )
// }

// function mapPerformanceToXlsx(series, categories) {
//     return [
//         {
//             sheet: '1 Quarter',
//             columns: [
//                 {
//                     label: 'Series',
//                     value: 'name'
//                 },
//                 ...categories
//                     .map(c => ({ label: c, value: c }))
//             ],
//             content: series
//                 .filter(s => s.type !== 'arearange')
//                 .map(s => {
//                     return {
//                         name: s.name || s.series_name,
//                         ...categories.reduce((values, c, index) => {
//                             let dp = s.data.find(dp => dp.category === c)

//                             if (!dp)
//                                 dp = s.data
//                                     .filter(dp => !Number.isNaN(Number(dp)))
//                                     [index]

//                             return {
//                                 ...values,
//                                 [c]: (dp !== undefined ? (dp.perf || dp) : null)
//                             }
//                         }, {})
//                     }
//                 })
//         },

//     ]
// }

const mapDisclaimersToHTML = fileText => {
    return fileText
        .split('\r\n\r')
        .map((line, lineNumber) => <p key={ lineNumber } className="small">{ line }</p>)
}