import React, { Fragment, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setAttendees, addAttendee, removeAttendee, cleanupAttendees } from '../store/attendees'
import { PlusCircle, XCircle } from 'react-bootstrap-icons'

const emailRegexPattern = `^(.+)@(.+)\.(.+)$`

const SelectAttendee = () => {
    const dispatch = useDispatch()
    const navigate  = useNavigate()
    const attendees = useSelector(state => state.attendees.attendees)
    const groupId = useSelector(state => state.attendees.groupId)

    useEffect(() => {
        for (let attendee of attendees) {
            document.getElementById(`attendee-${attendee.id}`).value = attendee.name
            document.getElementById(`email-${attendee.id}`).value = attendee.email
            document.getElementById(`firm-name-${attendee.id}`).value = attendee.firmName
        }
    }, [ groupId ])

    useEffect( () => () => dispatch(cleanupAttendees()), [] )

    const handleSubmit = e => {
        e.preventDefault()

        const attendeesToSet = attendees.map(attendee => {
            const name = document.getElementById(`attendee-${attendee.id}`).value
            const email = document.getElementById(`email-${attendee.id}`).value
            const firmName = document.getElementById(`firm-name-${attendee.id}`).value

            return {
                name,
                email,
                firmName,
                id: attendee.id
            }
        })

        dispatch(setAttendees({ attendees: attendeesToSet }))
        navigate('/')
        sessionStorage.setItem('attendees', JSON.stringify(attendeesToSet))
    }

    return (
        <Fragment>
            <main>
                <div className="content-wrapper-md">
                    <div className="form-box">
                        <div className="form-box__header">
                        </div>
                        <form className="sign-in" onSubmit={ handleSubmit }>
                            {
                                attendees[0].name ?
                                <Fragment>
                                    <h3>Current Attendees:</h3>
                                    <div id="attendee-info">
                                        {
                                            attendees.map((attendee, index) => attendee.name ? <label
                                                key={ attendee.id }>
                                                    { attendee.name } ({ attendee.email }, { attendee.firmName })
                                                    {
                                                        !!index && 
                                                        <XCircle size={ 18 } onClick={ () => dispatch(removeAttendee({ id: attendee.id })) } />
                                                    }
                                                    <br />
                                                </label>
                                            : null)
                                        }
                                        {/* <label>Current attendee: { attendee.name } ({ attendee.email }) { attendee.multipleAttendees ? <small>Multiple attendees</small> : null }</label> */}
                                    </div>
                                </Fragment>
                                : null
                            }
                            <ul>
                                {
                                    attendees.map((attendee, index) => <AttendeeInputs
                                        key={ attendee.id }
                                        attendee={ attendee }
                                        index={ index }
                                        addAttendee={ addAttendee }
                                        removeAttendee={ removeAttendee }
                                        isLast={ attendees.length - 1 === index}
                                    />)
                                }
                                {/* <AttendeeInputs /> */}
                                {/* <li>
                                    <label htmlFor="attendee">Full name</label>
                                    <br />
                                    <input type="text" id="attendee" name="attendee" required ref={ nameInput } />
                                    <br />
                                </li>
                                <li>
                                    <label htmlFor="attendee">Email address</label>
                                    <br />
                                    <input type="email" id="email" name="email" pattern={ emailRegexPattern } required ref={ emailInput } />
                                    <br />
                                </li>
                                <li>
                                    <label htmlFor="firm-name">Firm name</label>
                                    <br />
                                    <input type="text" id="firm-name" name="firm-name" required ref={ firmNameInput } />
                                    <br />
                                </li>
                                <li>
                                    <label htmlFor="multiple-attendees">
                                        Multiple attendees
                                    </label>
                                    <br />
                                    <br />
                                    <br />
                                    <label className="switch">
                                        <input type="checkbox" name="multiple-attendees" ref={ multipleAttendeesCheck } />
                                        <span className="slider round"></span>
                                    </label>
                                </li> */}
                            </ul>
                            <div id="attendee-submit-button">
                                <input type="submit" value="Submit" />
                            </div>
                        </form>
                    </div>
                </div>
            </main>
            <footer>
                <div className="content-wrapper-lg">
                <span className="teal-footer">Disclaimers</span>
                    <p>
                        Stadion Money Management, LLC ("Stadion") is a registered investment adviser under the Investment Advisers Act of 1940. Registration does not imply a certain level of skill or
                        training. More information about Stadion, including fees, can be found in Stadion's ADV Part 2, which is available free of charge.
                    </p> 

                    <p>
                        Expense ratios and performance shown for investments do not include recordkeeper-specific fees which would increase the expense for each investment.
                    </p>

                    <p>
                        The information in this presentation is for informational purposes only and does not constitute an offer to sell, a solicitation of an offer to buy, or a recommendation of any 
                        security that may be referenced in this presentation. It should not be assumed that any investment recommendations or decisions Stadion makes in the future will be profitable. 
                        Stadion reserves the right to modify its current investment strategies and techniques based on changing market dynamics or client needs.
                    </p>

                    <p>
                        The S&P Target Date Index Series is composed of eleven multi-asset class indices, each corresponding to a particular target retirement date. The asset class mix for each 
                        index is determined once a year through a process designed to reflect the overall investment opportunity of the represented markets. Each index is fully investable, 
                        with varying levels of exposure to equities and fixed income. To create its target date benchmark, S&P Indices conducts an annual survey of target date funds each year in April. 
                        Funds are required by the SEC to report their holdings quarterly. At the time of the annual index review, it was the most recent quarterly holdings data for each fund in its survey. 
                        The overall process is as follows: (i) Funds are identified as Target Date funds from the Morningstar or Lipper databases and sorted by asset size. Fund families not meeting the 
                        minimum asset threshold of US $100 million are removed from consideration. (ii) Fund holdings are drawn from the latest period available using commercial databases and the SEC's Edgar web site. 
                        (iii) Asset class exposures for the funds are derived by mapping the fund holdings to their corresponding asset class category. In cases where surveyed funds hold balanced or other multi-asset class funds, 
                        S&P Indices look through to the underlying asset allocation of these funds to determine their net effect on the asset allocation of the surveyed fund. It is not possible to invest directly in indexes which 
                        are unmanaged and do not incur fees. Each target date allocation is created and retired according to a pre-determined schedule related to the relevant target date.
                    </p>

                    <p>
                        The S&P &P Target Date 2060 Index is designed to represent a small, style-specific derived consensus of asset class exposure and glide path for target dates to 2060. The index is designed to help defined 
                        contribution plan sponsors screen, select, and monitor appropriate target date funds. The launch date was June 1, 2016. The inception date for the S&P Target Date 2060 Index is not shown due to this data 
                        being measured from the inception date of the lineup rather than the inception date of the S&P Target Date 2060 Index. The S&P &P Target Date 2050 Index (Moderate Glide Path) is designed to represent a 
                        broadly derived consensus of asset class exposures, allocating to equities and fixed income according to a moderate glide path for the target date 2050. The launch date was August 17, 2020. 
                        The inception date for the S&P Target Date 2050 Index is not shown due to this data being measured from the inception date of the lineup rather than the inception date of the S&P Target Date 2050 Index. 
                        The S&P Target Date 2040 Index is designed to represent a broadly derived consensus of asset class exposure and glide path for target date year 2040. The index allocates to equities and fixed income at varying levels, 
                        according to a pre-determined schedule related to the respective target date. The launch date was September 25, 2008. The inception date for the S&P Target Date 2040 Index is not shown due to this data being measured 
                        from the inception date of the lineup rather than the inception date of the S&P Target Date 2040 Index. The S&P Target Date Through 2030 Index is designed to represent a small, style-specific derived consensus of asset 
                        class exposure and glide path for target dates through 2030. The index is designed to help defined contribution plan sponsors screen, select, and monitor appropriate target date funds. The launch date was June 7, 2012. 
                        The inception date for the S&P Target Date 2030 Index is not shown due to this data being measured from the inception date of the lineup rather than the inception date of the S&P Target Date 2030 Index. 
                        The S&P Target Date 2020 Index is designed to represent a broadly derived consensus of asset class exposure and glide path for target date year 2020. The index allocates to equities and fixed income at varying levels, 
                        according to a pre-determined schedule related to the respective target date. The launch date was September 25, 2008. The inception date for the S&P Target Date 2020 Index is not shown due to this data being measured 
                        from the inception date of the lineup rather than the inception date of the S&P Target Date 2020 Index.
                    </p>

                    <p>SMM-2210-51</p>
                </div>
        </footer>
        </Fragment>
    )
}

const AttendeeInputs = props => {
    const dispatch = useDispatch()
    const { attendee, isLast, index } = props

    const isMain = !index

    return (
        <Fragment>
            <li>
                <label>{ isMain ? 'Main attendee' : `Additional Attendee ${index}` }</label>
            </li>
            <li>
                <label htmlFor="attendee">Full name</label>
                <br />
                <input type="text" id={ `attendee-${attendee.id}` } name="attendee" required />
                <br />
            </li>
            <li>
                <label htmlFor="attendee">Email address</label>
                <br />
                <input type="email" id={ `email-${attendee.id}` } name="email" pattern={ emailRegexPattern } required />
                <br />
            </li>
            <li>
                <label htmlFor="firm-name">Firm name</label>
                <br />
                <input type="text" id={ `firm-name-${attendee.id}` } name="firm-name" required />
                <br />
            </li>
            <li>
                {
                    !isMain &&
                    <button
                        type="button"
                        className="danger"
                        onClick={ () => dispatch(removeAttendee({ id: attendee.id })) }
                    >
                        <XCircle /> Remove
                    </button>
                }
                {
                    isLast &&
                    <button
                        type="button"
                        class="primary"
                        onClick={ () => dispatch(addAttendee()) }
                    >
                        <PlusCircle /> Add Another Attendee
                    </button>
                }
            </li>
            {/* // <li>
            //     <label htmlFor="multiple-attendees">
            //         Multiple attendees
            //     </label>
            //     <br />
            //     <br />
            //     <br />
            //     <label className="switch">
            //         <input type="checkbox" name="multiple-attendees" />
            //         <span className="slider round"></span>
            //     </label>
            // </li> */}
        <hr />
        </Fragment>
    )
}

export default SelectAttendee