import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    showIncludedFundsOnly: false
}

const lineupPageSlice = createSlice({
    name: 'lineupPage',
    initialState,
    reducers: {
        toggleShowIncludedFundsOnly(state) {
            state.showIncludedFundsOnly = !state.showIncludedFundsOnly
        }
    }
})

// export const { toggleShowIncludedFundsOnly } = lineupPageSlice.actions

// export default lineupPageSlice.reducer

export const { actions, reducer } = lineupPageSlice